// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_d348eff","type-body-2":"type-body-2_bc1428a","type-body-3":"type-body-3_b2889de","type-body-4":"type-body-4_a700849","type-section-title":"type-section-title_f1e5173","type-notes":"type-notes_af933f1","type-item-title":"type-item-title_cf778b2","type-item-subtitle":"type-item-subtitle_cd5aa4a","type-subtitle":"type-subtitle_c297a6a","type-title":"type-title_d260db7","title":"title_fe6a47e","logEntriesLoading":"logEntriesLoading_bdc2d60","noLogsPlaceholder":"noLogsPlaceholder_f916970","logGroup":"logGroup_acff755","logEntries":"logEntries_aada6c9","logEntry":"logEntry_dd69946","colorBadge":"colorBadge_da95bdc","type":"type_db9efd6","message":"message_ef01611","note":"note_c7218ef","black":"black_bc3fc20","blue":"blue_ffe9889","green":"green_b16d287","red":"red_fd70756","yellow":"yellow_d30f721"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_d348eff\",\"type-body-2\":\"type-body-2_bc1428a\",\"type-body-3\":\"type-body-3_b2889de\",\"type-body-4\":\"type-body-4_a700849\",\"type-section-title\":\"type-section-title_f1e5173\",\"type-notes\":\"type-notes_af933f1\",\"type-item-title\":\"type-item-title_cf778b2\",\"type-item-subtitle\":\"type-item-subtitle_cd5aa4a\",\"type-subtitle\":\"type-subtitle_c297a6a\",\"type-title\":\"type-title_d260db7\",\"title\":\"title_fe6a47e\",\"logEntriesLoading\":\"logEntriesLoading_bdc2d60\",\"noLogsPlaceholder\":\"noLogsPlaceholder_f916970\",\"logGroup\":\"logGroup_acff755\",\"logEntries\":\"logEntries_aada6c9\",\"logEntry\":\"logEntry_dd69946\",\"colorBadge\":\"colorBadge_da95bdc\",\"type\":\"type_db9efd6\",\"message\":\"message_ef01611\",\"note\":\"note_c7218ef\",\"black\":\"black_bc3fc20\",\"blue\":\"blue_ffe9889\",\"green\":\"green_b16d287\",\"red\":\"red_fd70756\",\"yellow\":\"yellow_d30f721\"}";
        // 1734120902336
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  